import React from 'react';
import { getFormatSpecificImageProps, NextFutureImage } from '@konsus/image';
import { heroBgWrapperCva, superHeroBackgroundVariants } from '../variants';
import { type SuperHeroBackgroundProps } from '../types';

const SuperHeroBackground: React.FC<SuperHeroBackgroundProps> = ({
  heroContainerDesktop,
  heroContainerMobile,
  backgroundSettings
}) => {
  if (!backgroundSettings.image) {
    return null;
  }

  const extraImageProps = getFormatSpecificImageProps(backgroundSettings.image);
  const extraMobileImageProps = backgroundSettings.imageMobile
    ? getFormatSpecificImageProps(backgroundSettings.imageMobile)
    : {};

  return (
    <div className={heroBgWrapperCva({ heroContainerDesktop })}>
      <div
        className={superHeroBackgroundVariants({
          heroContainerDesktop,
          imageSize: backgroundSettings.imageSize
        })}
      >
        <NextFutureImage
          image={backgroundSettings.image}
          requestWidth={3000}
          className='<768:hidden w-full object-cover object-top'
          alt=''
          fetchPng={backgroundSettings.fetchPng}
          priority
          {...extraImageProps}
        />
      </div>

      {heroContainerMobile && backgroundSettings.imageMobile ? (
        <div
          className={superHeroBackgroundVariants({
            heroContainerMobile,
            imageSizeMobile: backgroundSettings.imageSizeMobile
          })}
        >
          <NextFutureImage
            image={backgroundSettings.imageMobile}
            className='768:hidden !h-full object-cover object-top'
            alt=''
            {...extraMobileImageProps}
          />
        </div>
      ) : null}
    </div>
  );
};

export default SuperHeroBackground;
