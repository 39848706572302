import { cva } from 'class-variance-authority';
import {
  contentWidthOptions,
  heroVariantOptions,
  heroContainerOptions,
  heroAlignmentOptions,
  contentComponentOptions,
  heroLeftComponentOptions
} from '@superside/sanity-studio/schema/blocks/SuperHero/types';
import { imageSizeMobileOptions, imageSizeOptions, paddingMobileOptions } from './types';

export const heroLeftExtraComponentVariants = cva('', {
  variants: {
    extraComponentAlignment: {
      centered: 'self-center',
      bottom: '768:self-end'
    },
    heroLeftComponent: {
      [heroLeftComponentOptions.VISUAL_ASSET]: '1280:max-w-[656px] max-w-[328px]',
      [heroLeftComponentOptions.TRUSTPILOT_REVIEW]: '',
      [heroLeftComponentOptions.FOMO_COUNTDOWN]: '',
      [heroLeftComponentOptions.FORM]: '<768:contents text-start',
      [heroLeftComponentOptions.STAR_RATING]: '',
      [heroLeftComponentOptions.ANIMATED_ASSET]:
        '-my-10 flex w-[calc(100%_-_16px)] justify-end overflow-hidden py-10'
    }
  }
});

export const contentComponentVariants = cva(
  '768:self-start 768:text-left mt-10 self-center text-center',
  {
    variants: {
      heroVariant: {
        [heroVariantOptions.CENTER]: '1280:self-center',
        [heroVariantOptions.LEFT]: ''
      },
      contentComponent: {
        [contentComponentOptions.LOGO_SALAD]: 'w-full',
        [contentComponentOptions.TRUSTPILOT_REVIEW]: 'w-full',
        [contentComponentOptions.STAR_RATING]: '',
        [contentComponentOptions.FOMO_COUNTDOWN]: ''
      },
      contentWidth: {
        [contentWidthOptions.XL]: '',
        [contentWidthOptions.L]: '',
        [contentWidthOptions.M]: '',
        [contentWidthOptions.S]: '',
        [contentWidthOptions.CUSTOM]: ''
      }
    },
    compoundVariants: [
      {
        contentComponent: [contentComponentOptions.LOGO_SALAD],
        contentWidth: [contentWidthOptions.XL],
        className: '1280:max-w-[calc(1064px-theme(spacing.6))]'
      },
      {
        contentComponent: [contentComponentOptions.LOGO_SALAD],
        contentWidth: [contentWidthOptions.L],
        className: '1280:max-w-[calc(840px-theme(spacing.6))]'
      },
      {
        contentComponent: [contentComponentOptions.LOGO_SALAD],
        contentWidth: [contentWidthOptions.M],
        className: '1280:max-w-[calc(700px-theme(spacing.6))]'
      },
      {
        contentComponent: [contentComponentOptions.LOGO_SALAD],
        contentWidth: [contentWidthOptions.S],
        className: '1280:max-w-[calc(568px-theme(spacing.6))]'
      }
    ]
  }
);

export const superHeroContentVariants = cva(
  'text-balance marble:text-wrap -mx-6 flex w-full flex-col overflow-hidden px-6',
  {
    variants: {
      heroVariant: {
        [heroVariantOptions.CENTER]:
          '768:items-start 768:text-left 1280:items-center 1280:text-center items-center text-center',
        [heroVariantOptions.LEFT]: ''
      },
      contentWidth: {
        // FIXME remove hard coded marble sizes once we can better adjust it via Sanity
        [contentWidthOptions.XL]: 'marble:max-w-[1000px] max-w-[1064px]',
        [contentWidthOptions.L]: 'marble:1280:max-w-[1000px] marble:max-w-[700px] max-w-[840px]',
        [contentWidthOptions.M]: 'marble:1280:max-w-[1000px] marble:max-w-[600px] max-w-[1000px]',
        [contentWidthOptions.S]: 'max-w-[568px]',
        [contentWidthOptions.CUSTOM]:
          '1280:max-w-[var(--custom-width-l)] 1367:max-w-[var(--custom-width-xl)] 768:max-w-[var(--custom-width-tablet)]'
      }
    }
  }
);

export const superHeroContentWrapperVariants = cva(
  '<768:text-center <768:items-center 768:flex-1 flex w-full flex-col gap-6',
  {
    variants: {
      heroVariant: {
        [heroVariantOptions.CENTER]: '1280:justify-center 1280:items-center 1280:text-center',
        [heroVariantOptions.LEFT]: '768:flex-row 768:justify-between'
      },
      extraComponentAlignment: {
        top: '',
        centered: '',
        bottom: ''
      },
      heroContainerDesktop: {
        [heroContainerOptions.DYNAMIC]: '',
        [heroContainerOptions.FIXED]: '',
        [heroContainerOptions.CUSTOM]: ''
      },
      heroContainerMobile: {
        [heroContainerOptions.DYNAMIC]: '',
        [heroContainerOptions.FIXED]: '',
        [heroContainerOptions.CUSTOM]: ''
      },
      heroAlignmentDesktop: {
        [heroAlignmentOptions.BOTTOM]: '',
        [heroAlignmentOptions.CENTER]: '',
        [heroAlignmentOptions.TOP]: ''
      },
      heroAlignmentMobile: {
        [heroAlignmentOptions.BOTTOM]: '',
        [heroAlignmentOptions.CENTER]: '',
        [heroAlignmentOptions.TOP]: ''
      },
      imageSizeMobile: {
        [imageSizeMobileOptions.S]: '',
        [imageSizeMobileOptions.M]: '',
        [imageSizeMobileOptions.L]: ''
      },
      paddingMobile: {
        [paddingMobileOptions.NONE]: '',
        [paddingMobileOptions.TOP]: '',
        [paddingMobileOptions.BOTTOM]: ''
      }
    },
    compoundVariants: [
      {
        heroContainerMobile: [heroContainerOptions.FIXED],
        imageSizeMobile: imageSizeMobileOptions.S,
        className: '<768:h-[calc(720px-theme(spacing.10)-theme(spacing.mobileHeader))]'
      },
      {
        heroContainerMobile: [heroContainerOptions.FIXED],
        imageSizeMobile: imageSizeMobileOptions.M,
        className: '<768:h-[calc(800px-theme(spacing.10)-theme(spacing.mobileHeader))]'
      },
      {
        heroContainerMobile: [heroContainerOptions.FIXED],
        imageSizeMobile: imageSizeMobileOptions.L,
        className: '<768:h-[calc(880px-theme(spacing.10)-theme(spacing.mobileHeader))]'
      },
      {
        heroContainerDesktop: [heroContainerOptions.FIXED],
        heroAlignmentDesktop: [heroAlignmentOptions.TOP],
        className: '768:self-start'
      },
      {
        heroContainerDesktop: [heroContainerOptions.FIXED],
        heroAlignmentDesktop: [heroAlignmentOptions.CENTER],
        className: '768:self-center'
      },
      {
        heroContainerDesktop: [heroContainerOptions.FIXED],
        heroAlignmentDesktop: [heroAlignmentOptions.BOTTOM],
        className: '768:self-end'
      },
      {
        heroContainerMobile: heroContainerOptions.DYNAMIC,
        paddingMobile: paddingMobileOptions.NONE,
        className: '<768:pb-4'
      },
      {
        heroContainerMobile: heroContainerOptions.DYNAMIC,
        paddingMobile: paddingMobileOptions.TOP,
        className: '<768:pt-20 <768:pb-4'
      },
      {
        heroContainerMobile: heroContainerOptions.DYNAMIC,
        paddingMobile: paddingMobileOptions.BOTTOM,
        className: '<768:pb-34'
      },
      {
        heroContainerMobile: heroContainerOptions.FIXED,
        heroAlignmentMobile: heroAlignmentOptions.BOTTOM,
        className: '<768:justify-end'
      },
      {
        heroContainerMobile: heroContainerOptions.FIXED,
        heroAlignmentMobile: heroAlignmentOptions.CENTER,
        className: '<768:justify-center'
      },
      {
        heroVariant: heroVariantOptions.LEFT,
        heroContainerDesktop: heroContainerOptions.DYNAMIC,
        extraComponentAlignment: 'top',
        className: 'items-start'
      },
      {
        heroVariant: heroVariantOptions.LEFT,
        heroContainerDesktop: heroContainerOptions.DYNAMIC,
        extraComponentAlignment: 'centered',
        className: 'items-center'
      },
      {
        heroVariant: heroVariantOptions.LEFT,
        heroContainerDesktop: heroContainerOptions.DYNAMIC,
        extraComponentAlignment: 'bottom',
        className: 'items-end'
      }
    ]
  }
);

export const superHeroContainerVariants = cva('<768:pt-10 flex', {
  variants: {
    heroContainerDesktop: {
      [heroContainerOptions.DYNAMIC]: '768:pt-18',
      [heroContainerOptions.FIXED]: '1280:pt-20 768:pt-14 flex-1 pt-10',
      [heroContainerOptions.CUSTOM]: ''
    }
  }
});

export const superHeroBackgroundVariants = cva('absolute z-0 [&_*]:!h-full', {
  variants: {
    heroContainerDesktop: {
      [heroContainerOptions.DYNAMIC]: '<768:hidden',
      [heroContainerOptions.FIXED]: '<768:hidden',
      custom:
        '1367:block 1367:[&_img]:!aspect-[2560/1600] 1367:w-[2560px] 1367:left-1/2 1367:-translate-x-1/2 h-full object-cover [&_span]:!h-full '
    },
    heroContainerMobile: {
      [heroContainerOptions.DYNAMIC]: '768:hidden',
      [heroContainerOptions.FIXED]: '768:hidden',
      custom: '768:hidden h-full [&_span]:!h-full'
    },
    imageSize: {
      [imageSizeOptions.DEFAULT]: '',
      [imageSizeOptions.LARGE]: ''
    },
    imageSizeMobile: {
      [imageSizeMobileOptions.S]: '',
      [imageSizeMobileOptions.M]: '',
      [imageSizeMobileOptions.L]: ''
    }
  },
  compoundVariants: [
    {
      heroContainerDesktop: [heroContainerOptions.DYNAMIC, heroContainerOptions.FIXED],
      className:
        '1280:left-1/2 1280:-translate-x-1/2 768:w-[1740px] 1367:w-[2090px] 1537:w-[2560px] right-0'
    },
    {
      heroContainerDesktop: [heroContainerOptions.DYNAMIC, heroContainerOptions.FIXED],
      imageSize: [imageSizeOptions.DEFAULT],
      className: '[&_img]:aspect-[2560/965]'
    },
    {
      heroContainerDesktop: [heroContainerOptions.DYNAMIC, heroContainerOptions.FIXED],
      imageSize: [imageSizeOptions.LARGE],
      className: '1537:h-[2560px] 768:h-[1740px] 1367:h-[2090px] [&_img]:aspect-square'
    },
    {
      heroContainerMobile: [heroContainerOptions.FIXED, heroContainerOptions.DYNAMIC],
      imageSizeMobile: imageSizeMobileOptions.S,
      className: '<768:h-[calc(720px)]'
    },
    {
      heroContainerMobile: [heroContainerOptions.FIXED, heroContainerOptions.DYNAMIC],
      imageSizeMobile: imageSizeMobileOptions.M,
      className: '<768:h-[calc(800px)]'
    },
    {
      heroContainerMobile: [heroContainerOptions.FIXED, heroContainerOptions.DYNAMIC],
      imageSizeMobile: imageSizeMobileOptions.L,
      className: '<768:h-[calc(880px)]'
    }
  ]
});

export const heroBgWrapperCva = cva('768:overflow-hidden absolute bottom-0 w-full', {
  variants: {
    heroContainerDesktop: {
      [heroContainerOptions.FIXED]: '768:h-screen 768:max-h-[965px]',
      [heroContainerOptions.DYNAMIC]: '768:h-[2560px]',
      custom:
        '768:h-[1600px] <500:block 1367:block 1367:-top-[calc(var(--pt)+var(--pb))] -top-[calc(var(--ptMobile)+var(--pbMobile))] -z-10 hidden h-[1180px]'
    }
  },
  compoundVariants: [
    {
      heroContainerDesktop: [heroContainerOptions.FIXED, heroContainerOptions.DYNAMIC],
      className: '768:-top-header -top-mobileHeader'
    }
  ]
});
