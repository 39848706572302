export type SuperHeroValidation = {
  parent: {
    heroVariant: HeroVariantTypes;
    heroContainerDesktop: HeroContainerTypes;
    heroContainerMobile: HeroContainerTypes;
    heroAlignment: HeroAlignmentTypes;
    heroLeftComponent: HeroLeftComponentTypes;
    contentComponent: ContentComponentTypes;
    hasCustomizedMobile: boolean;
    ctaVariant: CtaVariantTypes;
    contentWidth: ContentWidthOptions;
  };
};

export const heroVariantOptions = {
  CENTER: 'center',
  LEFT: 'left'
} as const;

export type HeroVariantTypes = (typeof heroVariantOptions)[keyof typeof heroVariantOptions];

export const heroContainerOptions = {
  FIXED: 'fixed',
  DYNAMIC: 'dynamic',
  CUSTOM: 'custom'
} as const;

export type HeroContainerTypes = (typeof heroContainerOptions)[keyof typeof heroContainerOptions];

export const heroAlignmentOptions = {
  CENTER: 'center',
  TOP: 'top',
  BOTTOM: 'bottom'
} as const;

export type HeroAlignmentTypes = (typeof heroAlignmentOptions)[keyof typeof heroAlignmentOptions];

const baseComponentOptions = {
  TRUSTPILOT_REVIEW: 'trustpilotReview',
  STAR_RATING: 'starRating',
  FOMO_COUNTDOWN: 'fomoCountdown'
} as const;

export const heroLeftComponentOptions = {
  ...baseComponentOptions,
  VISUAL_ASSET: 'visualAsset',
  FORM: 'embedFormOptions',
  ANIMATED_ASSET: 'animatedAsset'
} as const;

export const contentComponentOptions = {
  ...baseComponentOptions,
  LOGO_SALAD: 'miniLogoSalad'
} as const;

export type HeroLeftComponentTypes =
  (typeof heroLeftComponentOptions)[keyof typeof heroLeftComponentOptions];

export type ContentComponentTypes =
  (typeof contentComponentOptions)[keyof typeof contentComponentOptions];

export const ctaVariantOptions = {
  NO_CTA: 'withoutCta',
  SINGLE_CTA: 'singleCta',
  DOUBLE_CTA: 'doubleCta',
  FIELD_CTA: 'fieldCta'
} as const;

export type CtaVariantTypes = (typeof ctaVariantOptions)[keyof typeof ctaVariantOptions];

export const contentWidthOptions = {
  XL: 'xl',
  L: 'lg',
  M: 'md',
  S: 'sm',
  CUSTOM: 'custom'
} as const;

export type ContentWidthOptions = (typeof contentWidthOptions)[keyof typeof contentWidthOptions];
