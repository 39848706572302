import {
  type CtaVariantTypes,
  type ContentWidthOptions,
  type HeroVariantTypes,
  type HeroContainerTypes,
  type HeroAlignmentTypes,
  type HeroLeftComponentTypes,
  type ContentComponentTypes
} from '@superside/sanity-studio/schema/blocks/SuperHero/types';
import { type RichTextFomoType } from '@konsus/sanity-components/src/components/RichTextFomo/types';
import {
  type VisualAssetProps,
  type SanityAsset,
  type StarRatingProps,
  type TrustpilotReviewProps
} from '../../components';
import { type CtaProps } from '../../types/ctaButton';
import { type TrackingFormData } from '../../utils/useSubscribeForm';
import { type LogoSaladProps } from '../LogoSalad/types';
import { type FormObjectType } from '../../types/form';
import { type AnimatedAssetProps } from '../../components/AnimatedAsset/types';

export const imageSizeOptions = {
  DEFAULT: 'default',
  LARGE: 'large'
};

export const imageSizeMobileOptions = {
  S: 'S',
  M: 'M',
  L: 'L'
} as const;

export const paddingMobileOptions = {
  NONE: 'none',
  TOP: 'top',
  BOTTOM: 'bottom'
} as const;

interface CapturingFormType {
  submitButtonText: string;
  placeholderText: string;
  redirectLink?: string;
  hubspotFormId?: string;
  trackingEvents: TrackingFormData;
}

type ImageSizeType = (typeof imageSizeMobileOptions)[keyof typeof imageSizeMobileOptions];

export interface SuperHeroCtaVariantProps {
  ctaVariant: CtaVariantTypes;
  primaryCta: CtaProps;
  secondaryCta?: CtaProps;
  capturingForm: CapturingFormType;
  disableSecondaryCtaMobile?: boolean;
}

export interface SuperHeroBaseProps {
  heroContainerDesktop: HeroContainerTypes | 'custom';
  heroContainerMobile?: HeroContainerTypes | 'custom';
}

export interface SuperHeroContainerProps extends SuperHeroBaseProps, SuperHeroCtaVariantProps {
  contentWidth: ContentWidthOptions;
  customContentWidth?: { desktopXL: string; desktopL: string; tablet: string };
  heroVariant: HeroVariantTypes;
  superscript: string;
  title: string;
  headerRichText?: { content: Array<any> };
  description: { content: Array<any> };
  heroAlignmentDesktop: HeroAlignmentTypes;
  heroLeftComponent: HeroLeftComponentTypes;
  contentComponent: ContentComponentTypes;
  fomoCountdownContent: RichTextFomoType['node'];
  starRatingContent: StarRatingProps;
  miniLogoSaladContent: Pick<LogoSaladProps, 'items' | 'speed'>;
  trustpilotReviewContent: TrustpilotReviewProps;
  fomoCountdownHeroLeft: RichTextFomoType['node'];
  starRatingHeroLeft: StarRatingProps;
  visualAssetHeroLeft: VisualAssetProps;
  embedFormOptionsHeroLeft: FormObjectType;
  extraComponentAlignment: 'centered' | 'bottom';
  imageSizeMobile: ImageSizeType;
  heroAlignmentMobile?: HeroAlignmentTypes;
  paddingMobile?: (typeof paddingMobileOptions)[keyof typeof paddingMobileOptions];
  animatedAssetHeroLeft?: AnimatedAssetProps;
}

export interface SuperHeroBackgroundProps extends SuperHeroBaseProps {
  backgroundSettings: {
    image: SanityAsset;
    imageMobile: SanityAsset;
    imageSizeMobile: ImageSizeType;
    imageSize: ImageSizeType;
    fetchPng?: boolean;
  };
}

export interface SuperHeroProps extends SuperHeroContainerProps, SuperHeroBackgroundProps {
  hasCustomizedMobile: boolean;
  hashId?: string;
}
